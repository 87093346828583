:root {
  --animation-duration: 0.1s;
  --animation-in: 0.5s;
  --long-animation: 1.5s;
  --long-animation-out: 0.2s;
}

.fadeIn {
  animation: var(--animation-in) fadeInAn forwards;
}

.fadeInHome {
  animation: var(--long-animation) fadeInHomeAn forwards;
}

.fadeOutHome {
  animation: var(--long-animation-out) fadeOutHomeAn forwards;
}

.fadeOut {
  animation: var(--animation-duration) fadeOutAn forwards;
}

@keyframes fadeInHomeAn {
  from {
    opacity: 0;
    filter: blur(10px);
  }

  to {
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes fadeOutHomeAn {
  from {
    opacity: 1;
    filter: blur(0);
  }

  to {
    opacity: 0;
    filter: blur(10px);
  }

}

@keyframes fadeInAn {
  from {
    opacity: 0;
    filter: blur(5px);
    transform: translate(-40px, 0);
  }

  to {
    opacity: 1;
    filter: blur(0);
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOutAn {
  from {
    opacity: 1;
    filter: blur(0);
    transform: translate(0px, 0px);
  }

  to {
    transform: translate(-40px, 0);
    opacity: 0;
    filter: blur(10px);
  }
}